import GettingPatientsStarted from "../components/3.0_getting_patients_started/GettingPatientsStarted"
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"



const GettingStartedRefs = (props) => {
   return (
        <p className="cv-footnote"><strong>References: 1.</strong></p>
    )
}

export default class GPStarted extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (evt) => {
    this.setState(() => ({ modal: 'home' }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }

  render() {

    return (
       <>
       <Loader>
      <Layout>
        <SEO
          title="Getting Patients Started"
          keywords={[""]}
          description="Everything you need to help patients get started on VASCEPA: Dosing recommendations, samples, and savings cards to reduce the cost of VASCEPA."/>
        <GettingPatientsStarted isMobile={this.state.isMobile}/>
      </Layout>
      <ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
        <div className="ref-outer">
          <div className="footnotes-container">
            <div className='references'>
                <p className="cv-footnote hang">*Data on file.</p>
            </div>
          </div>
        </div>
      <Footer />
      </Loader>
     </>
    )
  }
}
