import React from "react"
import { Link } from "gatsby"

import './getting_patients_started.css'

/*images*/
import icon_01 from '../../images/3.0/landingpage_icons_v2-07.png'
import icon_02 from '../../images/3.0/landingpage_icons_v2-08.png'
import icon_03 from '../../images/3.0/landingpage_icons_v2-09.png'

import bck from "../../images/landingpage_images/3.0_landingpage_image.jpg";



export default (props) => {

  return (
    <section className="landing-wrapper grey-bg">
      <h1 style={{display: "none" }}>Dosing: Information about dosing, including direction for patients</h1>
        <div className="landing-grid-container three-column landing-grid-container-top-margin">
            {/* <h2 className="landing-headline">Getting Patients Started</h2> */}

            <article className="landing-content">
              <img className="landing-icon" src={icon_01} alt="Bottle and capsule icon"/>
              <h2 className="landing-subhead">Dosing</h2>
              <p className="landing-copy">Information about dosing, including direction for patients</p>
              <Link className="homeBtn" to="/dosing/">See more</Link>
            </article>
            {props.isMobile ? <hr/> : null}
            <article className="landing-content">
              <img className="landing-icon" src={icon_02} alt="Hand and money icon"/>
              <h2 className="landing-subhead">Savings &amp; more</h2>
              <p className="landing-copy">Savings options, VASCEPA samples, and mail order rebates</p>
              <Link className="homeBtn" to="/get-vascepa-now/">More info</Link>
            </article>
            {props.isMobile ? <hr/> : null}
            <article className="landing-content">
            <img className="landing-icon" src={icon_03} alt="Key icon"/>
              <h2 className="landing-subhead">Access &amp; coverage</h2>
              <p className="landing-copy">VASCEPA may be accessible and affordable for most patients*</p>
              <Link className="homeBtn" to="/access-and-coverage/">See why</Link>
            </article>
            {/* {props.isMobile ? <hr/> : null}
            <article className="landing-content">
            <img className="landing-icon" src={icon_04} alt=""/>
              <h2 className="landing-subhead">Patient resources</h2>
              <p className="landing-copy">Downloadable resources for your patients</p>
              <Link className="homeBtn" to="/patient-resources/">Get them now</Link>
            </article> */}

        </div>
    </section>
  )
}
